import React from "react"
import { Box, Text, SimpleGrid } from "@chakra-ui/react"

import Container from "../Container"
import SolutionDescription from "./components/solutionDescription"
import SolutionTitle from "./components/solutionTitle"
import SolutionMobile from "./components/solutionMobile"
import { SOLUTIONS } from "../../constants"
import solutionData from "../../json/about/solution.json"

const Solution = () => {
  const [active, setActive] = React.useState(SOLUTIONS[0])
  return (
    <Container bg="white" minHeight="calc(100vh - 80px)" paddingTop="20">
      <Text fontSize={["4xl", null, "6xl"]} fontWeight="bolder">
        {solutionData.title}
      </Text>
      <Text
        fontSize="xl"
        marginTop="5"
        maxW={["100%", null, "80%"]}
        fontWeight="medium"
      >
        {solutionData.description}
      </Text>
      <SimpleGrid
        display={["grid", "none"]}
        columns={1}
        marginTop="15"
        spacing={5}
        mt="20"
      >
        <SolutionMobile onChange={setActive} value={active} />
      </SimpleGrid>
      <SimpleGrid
        display={["none", null, "grid"]}
        columns={[1, null, 2]}
        marginTop="15"
        spacing={5}
        mt="20"
      >
        <SolutionTitle onChange={setActive} value={active} />
        <SolutionDescription
          problem={active.problem}
          solution={active.solution}
        />
      </SimpleGrid>
    </Container>
  )
}

export default Solution
