import React from "react"
import { Box, Text } from "@chakra-ui/react"

const SolutionDescription = ({ problem, solution }) => {
  return (
    <Box>
      <Text fontWeight="bold" fontSize="xl" color="secondary">
        THE PROBLEM
      </Text>
      <Text fontSize="md" fontWeight="medium" mt="2">
        {problem}
      </Text>
      <Text fontWeight="bold" fontSize="xl" color="secondary" mt="10">
        HOW WE SOLVE
      </Text>
      <Text fontSize="md" fontWeight="medium" mt="2">
        {solution}
      </Text>
    </Box>
  )
}

export default SolutionDescription
