import React from "react"
import Container from "../Container"
import { Text, Flex, Image, SimpleGrid } from "@chakra-ui/react"
import Tabs from "../tabs"
import { TECHNOLOGY } from "../../constants"
import { useAppContext } from "../../context"
import technologyData from "../../json/about/technology.json"

const TechnologyStack = () => {
  const tabs = React.useMemo(() => TECHNOLOGY.map(row => row.title), [])
  const [active, setActive] = React.useState(tabs[0])
  const imgs = React.useMemo(() => {
    const selectedTechnology = TECHNOLOGY.find(row => row.title === active)
    return selectedTechnology.images
  }, [active])

  const { images } = useAppContext()

  return (
    <Container bg="primary" minHeight="calc(100vh - 80px)" py="10">
      <Text fontSize="6xl" fontWeight="bolder" textAlign="center" color="dark">
        {technologyData.title}
      </Text>
      <Tabs tabs={tabs} onChange={setActive} active={active} />
      <SimpleGrid columns={[2, null, 4]} marginTop="15" spacing={5} mt="20">
        {imgs.map(row => (
          <Image src={images[row]} />
        ))}
      </SimpleGrid>
    </Container>
  )
}

export default TechnologyStack
