import React from "react"
import { Box, Text, Image } from "@chakra-ui/react"
import { SOLUTIONS } from "../../../../constants"
import { StaticImage } from "gatsby-plugin-image"
import { useAppContext } from "../../../../context"

const SolutionTitle = ({ value, onChange }) => {
  const { images } = useAppContext()
  return (
    <Box>
      {SOLUTIONS.map(item => (
        <Box
          flexDirection="row"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          marginBottom={5}
          onClick={e => onChange(item)}
          cursor="pointer"
        >
          <Image
            src={
              item.title !== value.title
                ? images[item.icon]
                : images[item.activeIcon]
            }
            loading="eager"
            quality={95}
          />
          <Text
            fontSize="xl"
            fontWeight="bold"
            marginLeft="2.5"
            color={item.title === value.title ? "dark" : "darkGrey"}
          >
            {item.title}
          </Text>
          {item.title === value.title && (
            <Box bg="secondary" marginLeft={3} width={5} height={1} />
          )}
        </Box>
      ))}
    </Box>
  )
}

export default SolutionTitle
