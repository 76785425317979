import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumb from "../components/breadcrumb"
import Solution from "../components/solution"
import TechnologyStack from "../components/techonologyStack"
import breadCrumbData from "../json/about/breadCrumb.json"

const WhatWeDoPage = () => (
  <Layout>
    <Seo title="What we do" />
    <Breadcrumb
      description={breadCrumbData.description}
      title={breadCrumbData.title}
      // image="who-we-work-with-breadcrumb-banner.png"
    />
    <Solution />
    <TechnologyStack />
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default WhatWeDoPage
