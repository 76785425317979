import React from "react"
import { Text, Box, Flex } from "@chakra-ui/react"
import { COLORS } from "../../constants/theme"

const Tabs = ({ tabs = [], onChange, active }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      my="20"
      px={["0", null, "20"]}
      flexDirection={["column", null, "row"]}
      flexWrap="wrap"
    >
      {tabs.map(tab => (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          onClick={() => onChange(tab)}
          cursor="pointer"
        >
          <Text
            color={tab === active ? "secondary" : "dark"}
            fontWeight="bold"
            fontSize="xl"
            textAlign="center"
          >
            {tab}
          </Text>
          {tab === active && (
            <Box
              marginTop="1"
              borderBottom={`3px solid ${COLORS.secondary}`}
              width={10}
            />
          )}
        </Flex>
      ))}
    </Flex>
  )
}

export default Tabs
